import React from "react";

import "./roadmap.css"

export default function Roadmap() {

    return(
    <div className="divRoadmap">
        <h1>888Whales — Vision & Roadmap</h1>
        <img src = "images/roadmap_img1.png" alt="roadmap_img1"/> 
        The whales journey has just begun. 
        Today, we are excited to share our vision and roadmap with our community.
        
        <h2>Vision</h2>
        888Whales goes beyond being used as a status symbol in the Twitter banner. It is the beginning of a decentralized generative art movement where generative art creators and lovers come together to discuss and realize ideas. Whale NFT holders will act as the governers of the decentralized generative art community.
        <p/><b>Benefits for whale NFT holders:</b>
        <ul>
            <li><b>Governance rights:</b> Vote on how to spend the funds in the community wallet (e.g., financing of generative art ideas, development of website, marketing events, etc.)</li>
            <li><b>Pre-buy rights:</b> Get exclusive pre-buy rights for community drops</li>
        </ul>
        <b>Benefits for generative art creators:</b>
        <ul>
            <li><b>Pool of buyers:</b> Get immediate access to generative art enthusiasts</li>
            <li><b>Marketing:</b> Reach a wider audience with the help of the community</li>
            <li><b>Feedback:</b> Discuss ideas and receive direct feedback from the community</li>
            <li><b>Knowledge sharing:</b> Exchange knowledge with other creators</li>
        </ul>
        <h2>Roadmap</h2>
        The roadmap for the 888Whales sale was designed to reward early adopters with giveaways and long-term whale holders with exclusive rights for all future community projects.
        <ul>
        <li>🍀 20%: 2 ETH giveaway to 5 random whale holders (0.4 ETH each)</li>
        <li>🎁 40%: 2 ETH giveaway to 5 random whale holders (0.4 ETH each)</li>
        <li>💵 60%: Launch of community wallet with 3 ETH</li>
        <li>🐋 80%:
            <ol type="i" start="1">
                <li> 2 ETH donation to a whale protection organization,</li>
                <li> lottery for whale watching trip in Iceland</li> 
            </ol>
        </li>
        <li>✨ 100%:<ol type="i" start="1">
            <li> Addition of another 6 ETH to community wallet,</li>  
            <li> voting rights for community wallet for all whale holders,</li> 
            <li> pre-buy rights for community projects for all whale holders</li>
            </ol>
            </li>
        </ul>
        <h2>Links</h2>
        Discord: <a href="https://discord.gg/rfH7Dtu3by">https://discord.gg/rfH7Dtu3by</a> <br/>
        Opensea: <a href="https://opensea.io/collection/888whales">https://opensea.io/collection/888whales</a><br/>
        Twitter: <a href="https://twitter.com/888whales_nft">https://twitter.com/888whales_nft</a><p/>   
    </div>)
}
<ul>
<li>First NFT collection targeting the Twitter banner space</li>
<li>No pre-sale, no pre-marketing, fair launch</li>
<li>Rarity is determined by the community and not by a pre-defined score</li>
<li>Generation is based on randomized mathematical wave algorithm</li>
<li>Whales are simply cool!</li>
</ul>
