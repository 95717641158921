import { useState } from "react";

const useGlobalState =() => {

    const rndInt = Math.floor(Math.random() * 888) + 1;
    console.log(rndInt);
    const [state, setState] = useState({value: "images/"+rndInt+".png", id:rndInt, list: []})

    const actions = (action) => {
       const  {type, payload} = action;
       switch (type) {
           case 'setState':
               return setState(payload);
            default:
               return state;
       }
    }
    return {state, actions}
}

export default useGlobalState