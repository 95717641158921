
import "./background.css"

export default function Background() {

    return(
    <div className="divBackground">
        <h1>888Whales — Launch of the first Twitter banner focused NFT collection</h1>
        <img src = "images/background_img1.png" alt="background_img1"/> 

        Ascended from mathematical waves, 888 Whales arrived in <a href="https://opensea.io/collection/888whales">open sea</a>.
        888Whales is a unique NFT collection of beautiful whales, created by a random generative algorithm and mined by the human eye.
        888Whales is the first NFT project that targets the Twitter banner space to complement your Twitter profile.
        
        <h2>Background</h2>
        The 888Whales collection was inspired by <a href="https://tylerxhobbs.com/fidenza">Fidenza’s</a> 
        idea to use mathematical algorithms to generate beautiful art and a drawing of a team member’s son which was showing colorful waves in different amplitudes and wavelengths.
        The original idea was to generate randomly colored waves, but during the screening of the first output images, we started spotting whales. 
        The idea for 888Whales was born and the search for whales began.
        
        <img src = "images/background_img2.png" alt="background_img2"/> 
        <div className="imgLegend">Left: Fidenza #313 by artist Tyler Hobbs. Image: Art Blocks — Right: Drawing by a 2-year-old kid</div>

        <h2>Whale mining</h2>
        In cryptocurrencies, mining is the process of finding a number that, when passed into a mathematical function, produces an output number that satisfies given target criteria. 
        For instance, in Bitcoin, miners loop through billions of numbers and pass them into a hash function with the goal to produce an output number that is lower than or equal to the given target.
        The search for whales was a comparable process.
         Discovering whales within mathematical wave functions involved generating thousands of output images and visually evaluating them against the given target which was the shape of a whale.
        
        <h2>What makes 888Whales unique?</h2>
        <ul>
        <li>First NFT collection targeting the Twitter banner space</li>
        <li>No pre-sale, no pre-marketing, fair launch</li>
        <li>Rarity is determined by the community and not by a pre-defined score</li>
        <li>Generation is based on randomized mathematical wave algorithm</li>
        <li>Whales are simply cool!</li>
        </ul>

        <h2>Why 888?</h2>
        The number pattern 888 commonly means financial abundance and material wealth, representing the nature of a crypto whale.
        
        <h2>NFT Sales process</h2>
        At the beginning of the sale, 10 randomly selected whales will be listed on <a href="https://opensea.io/collection/888whales">opensea</a> for ETH 0.0888 each. 
        New whales will be listed after the first are sold out. 
        The listing time, the number and the price of new whales will be announced in advance in our <a href="https://discord.gg/rfH7Dtu3by">discord</a> channel. 
        The listing price for new whales will be based on the historical median traded price, but at least ETH 0.0888 (this is to avoid increasing the sell pressure in a scenario where the median price falls below the initial price).
        
        <h2>Storage and image quality</h2>
        Each whale will be stored on IPFS before listing. This removes the reliance on centralized storage services and ensures permanent availability of the image. 
        The PNG image files are 11'625 x 3'850px in size, which is proportional to the Twitter banner frame, and has a resolution of 1'000 dpi, which is perfect for printing and/or digital display.
        
        <h2>Outlook</h2>
        Whale holders will have preferential treatment in all future airdrops of the team. If you got excited, join us on discord and help us to steer the journey of the project.
        
        <h2>Links</h2>
        Discord: <a href="https://discord.gg/rfH7Dtu3by">https://discord.gg/rfH7Dtu3by</a> <br/>
        Opensea: <a href="https://opensea.io/collection/888whales">https://opensea.io/collection/888whales</a><br/>
        Twitter: <a href="https://twitter.com/888whales_nft">https://twitter.com/888whales_nft</a><p/>
    </div>)
}

