import images from "./api-mock.json"
import React, {useState}  from 'react';
import {useContext} from 'react';
import VisibilitySensor from "react-visibility-sensor";
import Context from '../../store/context';
import "./ImgGrid.css";
import ContextColorFilter from '../../store/contextColorFilter';


export default function ImgGrid() {
    const {state} = useContext(ContextColorFilter);

    var imageList = []
    console.log(state.value)
    if (state.value === "all") {
        imageList = images.resources ;
      } else {
        imageList = images.resources.filter(cImg => cImg.mainColor === state.value); 
      }
    

    const [imagesShownArray, setImageShownArray] = useState(
        Array(imageList.length).fill(false)
    );

    const imageVisibleChange = (index, isVisible) => {
        if (isVisible) {
            setImageShownArray((currentImagesShownArray) => {
                currentImagesShownArray[index] = true;
                return[...currentImagesShownArray];
            });
        }
    };

    return(
    <div className='image-grid'>
        {imageList &&
         imageList.map((image, index) => (
            <VisibilitySensor 
                key={index}
                partialVisibility={true}
                onChange={(isVisible) => imageVisibleChange(index, isVisible)}
            >
                <GridGalleryCard 
                    imageUrl={image.url} 
                    imageName={image.name} 
                    show={(imagesShownArray[index])}
                />
            </VisibilitySensor>
        ))}
    </div>)
}

//Attention GridGalleryCard handleClick highly depending on div structure in return 
// main issue is that img is in background and click on image cannot be captured directly
function GridGalleryCard({imageUrl, imageName, show}){
    const {state, actions} = useContext(Context);

    const handleClick = (e) => {
        console.log(e);
        const url = e.target.offsetParent.offsetParent.children[1].src;
        const alt = e.target.offsetParent.offsetParent.children[1].alt;
        const id = alt.replace("888Whale #","")
        actions({type:'setState', payload:{...state, value:url, id:id}})
    };

    return(
        <div className={`${show ? "GridCardShown" : "GridCardHidden"}`}
        onClick={(e) => handleClick(e)}>

            <div className='OverlayParent'>
                <div className='ImgBackground'></div>
                <div className='ImgText'>
                    {imageName}
                </div>
            </div>
            <img 
                src={imageUrl} 
                alt={imageName}>
            </img>
        </div>)
}