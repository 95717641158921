import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import useGlobalState from './store/useGlobalState'
import Context from './store/context'

import useGlobalStateColorFilter from './store/useGlobalStateColorFilter'
import ContextColorFilter from './store/contextColorFilter'

const Index = () => {
  const store = useGlobalState();
  const storeColorFilter = useGlobalStateColorFilter();
  return(
    <Context.Provider value={store}>
      <ContextColorFilter.Provider value={storeColorFilter}>
        <App/>
      </ContextColorFilter.Provider>
    </Context.Provider>

  );
}

ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById('root')
);