import { useState } from "react";

const useGlobalStateColorFilter =() => {
    const [state, setState] = useState({value: "all"})

    const actions = (action) => {
       const  {type, payload} = action;
       switch (type) {
           case 'setState':
               return setState(payload);
            default:
               return state;
       }
    }
    return {state, actions}
}

export default useGlobalStateColorFilter