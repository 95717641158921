import React, {useState} from 'react';
import {useContext} from 'react'
import images from "./api-mock.json"
import Context from '../../store/context';
import FilterColor from './FilterColor';

import "./Preview.css"

import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Preview() {
    const {state, actions} = useContext(Context);
    const [imageList] = useState(images.resources);
    const [imagePfP, setImagePfP] = useState("images/pfp.png");

    const viewRandom = event => {
        const id = Math.floor(Math.random() * 888) + 1;
        const url = "images/"+id+".png";
        actions({type:'setState', payload:{...state, value:url, id:id}})
    }

    const fileSelectedHandler = event => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            setImagePfP(fileReader.result);
          };
        fileReader.readAsDataURL(event.target.files[0]);
    }

    /* assume index is whale number -1*/
    const openseaLink = imageList[state.id-1].opensea;

    return(
        <div className="DivPreview">
            <div>
                <div className="BannerPreview">
                    <img src={state.value} alt="all"></img>
                </div>
                <div className="DivPfp">
                    <img src={imagePfP} alt="pfp"></img>
                    <input type="file" onChange={fileSelectedHandler}/>
                </div>
            </div>

            <div className="Opensea">
                <Button onClick={viewRandom} variant="dark" size="sm">View Random</Button>{' '}
                <div className="DivFilterColor"><FilterColor/></div>
                <Button href={openseaLink} variant="dark" size="sm">View On OpenSea</Button>{' '}
            </div>
        </div>)

}
/*{state.value}  found = array.find(function (element)*/

