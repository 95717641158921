import React from 'react'
import styled from 'styled-components';
import MenuItems from './MenuItems'

const AppHeader = styled.header`
    background-color:  #141534;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    position: sticky;
    top: 0px;
    z-index: 100;
`;

export default function Header(props) {
    return(
        <AppHeader>
            <MenuItems/>
        </AppHeader>
    );
}