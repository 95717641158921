import React from 'react';
import {useContext} from 'react'
import ContextColorFilter from '../../store/contextColorFilter';
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css'
import "./FilterColor.css";


export default function FilterColor() {
    const {state, actions} = useContext(ContextColorFilter);

    const handleSelect = event => {
        console.log(event);
        console.log(state.value)
        const colorToFilter = event.replace("#/","");
        actions({type:'setState', payload:{...state, value:colorToFilter}})
        };

    return(
        <div>
        <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle variant="dark" id="dropdown-basic" size="sm" >
                Filter By Color
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item href="#/all"><img src="images/filtercolor/all.png" alt="all"/>all</Dropdown.Item>
                <Dropdown.Item href="#/beige"><img src="images/filtercolor/beige.png" alt="beige"/>beige</Dropdown.Item>
                <Dropdown.Item href="#/black"><img src="images/filtercolor/black.png" alt="black"/>black</Dropdown.Item>
                <Dropdown.Item href="#/blue"><img src="images/filtercolor/blue.png" alt="blue"/>blue</Dropdown.Item> 
                <Dropdown.Item href="#/gold"><img src="images/filtercolor/gold.png" alt="gold"/>gold</Dropdown.Item>
                <Dropdown.Item href="#/green"><img src="images/filtercolor/green.png" alt="green"/>green</Dropdown.Item>
                <Dropdown.Item href="#/grey"><img src="images/filtercolor/grey.png" alt="grey"/>grey</Dropdown.Item>   
                <Dropdown.Item href="#/multi-color"><img src="images/filtercolor/multi_color.png" alt="multi_color"/>multi-color</Dropdown.Item>
                <Dropdown.Item href="#/red"><img src="images/filtercolor/red.png" alt="red"/>red</Dropdown.Item>
                <Dropdown.Item href="#/rose"><img src="images/filtercolor/rose.png" alt="rose"/>rose</Dropdown.Item> 
                <Dropdown.Item href="#/white"><img src="images/filtercolor/white.png" alt="white"/>white</Dropdown.Item>
                <Dropdown.Item href="#/yellow"><img src="images/filtercolor/yellow.png" alt="yellow"/>yellow</Dropdown.Item>                  
            </Dropdown.Menu>
        </Dropdown>
        </div>
        )
}