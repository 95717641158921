/*npm install react-bootstrap@next bootstrap@5.1.0*/
import React from "react";
import 'styled-components'
import styled from 'styled-components'
import Header from './components/Header/Header';
import "./App.css"

import ImgGrid from './components/gallary/ImgGrid';
import Preview from './components/gallary/Preview';

import Background from './components/background/background';
import Roadmap from './components/roadmap/roadmap';
import {
BrowserRouter as Router,
Switch,
Route,
Redirect,
} from "react-router-dom";


const AppPage = styled.div`
  text-align: center;
  background-color: #dcdcdc;
`;


const PageContent = styled.div`
  max-width: 1020px;
  position: relative;
  left: 50%;
  background-color: white;
  transform: translate(-50%, 0);
  -moz-box-shadow: 0 0 10px #c8c8c8;
  -webkit-box-shadow: 0 0 10px #c8c8c8;
  box-shadow: 0 0 10px #c8c8c8;
  padding-bottom: 50px
`;

const App = () => {
  return (
    <Router>
      <AppPage>
      <Header/>
      <PageContent>
        <Switch>
          <Route exact path="/">
            <Preview/>
            <ImgGrid/>
          </Route>
          <Route path="/Story">
            <Background/>
          </Route>
          <Route path="/Roadmap">
            <Roadmap/>
          </Route>
          <Route>
            <Preview/>
            <ImgGrid/>
            <Redirect to ="/" />
          </Route>
        </Switch>
      </PageContent>
      </AppPage>
    </Router>
  );

}

export default App;
