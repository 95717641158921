import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import 'styled-components'
import styled from 'styled-components'
import { SocialIcon } from 'react-social-icons';
import {
  Route,
  Link  
} from "react-router-dom";
import "./MenuItems.css"

const DivMenuItems = styled.div`
  position:relative;  
  left: 50%;
  transform: translate(-50%, 0);
  display: flex; 
  
`;  



export default function MenuItems() {
  return (
    <DivMenuItems>
      <Route 
        path="/"
        render={(history) => (
          <Tabs value={history.location.pathname.includes('/trade') ? false : history.location.pathname } aria-label="" TabIndicatorProps={{style: {backgroundColor: "#70D9BA"}}}>
            <Tab label="Gallery" value={"/"} component={Link} to={"/"}/>
            <Tab label="Story" value={"/Story"} component={Link} to={"/Story"}/>
            <Tab label="Vision" value={"/Roadmap"} component={Link} to={"/Roadmap"}/> 
            <Tab style={{ minWidth: 10 }} label= {<SocialIcon url="https://discord.gg/RhZZfgube6" network="discord" bgColor="#ffffff" style={{ height: 35, width: 35 }}/>} value={""} />
            <Tab style={{ minWidth: 10 }} label={<SocialIcon url="https://twitter.com/888whales_nft" network="twitter" bgColor="#ffffff" style={{ height: 35, width: 35 }}/>} value={""} />
          </Tabs>
        )} 
      />

    </DivMenuItems>
  );
}